@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: Vazir;
    src: url("../public/Vazirmatn-Bold.woff2");
}

* {
    font-family: Vazir, serif !important;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

@media only screen and (max-width: 600px) {
    .r-container {
        flex-direction: column !important;
        gap: 10px
    }
}

.icon-list {
    display: flex;
}